import {Controller} from "stimulus"


export default class extends Controller {

    connect() {
        this.object = this.element.getAttribute('data-object');
        this.event = this.element.getAttribute("data-event");
        this.action = this.element.getAttribute("data-action");
        this.eventData = this.element.getAttribute("data-data");


        if (this.event && this.action) {
            this.prepareEvent();
        } else {
            this.prepareSimpleEvent();
        }
    }

    prepareEvent() {
        if (this.event === 'eec.detail') {
            this.eecDetail();
        }else if(this.event === 'begin_checkout'){
            this.eecCheckout();
        }else if(this.event === 'purchase'){
            this.eecPurchase();
        }else if(this.event === 'view_item'){
            this.eecViewItem();
        }else if(this.event === 'view_item_list'){
            this.eecViewItem();
        }
    }

    prepareSimpleEvent() {

        if (this.eventData) {
            let layer = {
                [this.object]: JSON.parse(this.eventData)
            }
            this.pushData(layer);
        }
    }

    initialize() {
        this.dataLayer = window.dataLayer;
        this.isMain = this.element.getAttribute('data-main');
        if(this.isMain){
            EventManager.listen('gtm-event', payload => {
                this.onGtmEvent(payload);
            });
        }

    }

    onGtmEvent(data){

        this.event = data.event;
        this.action = data.action;
        this.eventData = data.eventData;

        if( this.event  === 'add_to_cart'){
            this.eecAdd();
        }else if(this.event === 'eec.remove'){
            this.eecRemove();
        }else if(this.event === 'add_to_wishlist'){
            this.add_to_wishlist();
        }
    }

    eecCheckout(){
        var cart = JSON.parse(this.eventData);
        var cartItems = [];

        cart.rows.forEach(function (row){
            console.log(row);
            var rowData = {
                item_id: row.id,
                item_name: row.name,
                discount: (row.originalPrice - row.price),
                item_brand: row.manufacturer,
                price: row.price,
                quantity: row.quantity
            };
            if(row.categories.length > 0){
                rowData['item_category'] = row.categories[0];
            }
            row.categories.forEach(function (category, key){
                if(key>0){
                    rowData['item_category' + (key + 1)] = category;
                }
            })

            cartItems.push(rowData);
        })

        let layer = {
            event: this.event,
            'ecommerce': {
                'currency': "USD",
                'value': cart.total,
                'coupon': cart.promocode?cart.promocode:'null',
                'items': cartItems
            }
        }

        this.pushData({ ecommerce: null });

        this.pushData(layer);
    }

    eecPurchase(){
        let layer = {
            event: 'purchase',
            ecommerce: JSON.parse(this.eventData)
        }

        this.pushData({ ecommerce: null });
        this.pushData(layer);
    }

    eecRemove(){
        let layer = {
            event: this.event,
            ecommerce: {
                remove: {
                    actionField: {
                        list: this.action
                    },
                    products: [this.eventData]
                },
            }
        }
        this.pushData(layer);
    }

    eecAdd(){
        var itemData = {
            item_id: this.eventData.id,
            item_name: this.eventData.name,
            affiliation: 'eBooksBridge',
            item_brand: this.eventData.brand,
            price: this.eventData.price,
            quantity: this.eventData.quantity
        };
        if(this.eventData.categories){
            if(this.eventData.categories.length > 0){
                itemData['item_category'] = this.eventData.categories[0];
            }
            this.eventData.categories.forEach(function (category, key){
                if(key>0){
                    itemData['item_category' + (key + 1)] = category;
                }
            })
        }

        let layer = {
            event: this.event,
            ecommerce: {
                currency: "USD",
                value: itemData['price'],
                items: [
                    itemData
                ]
            }
        }
        this.pushData({ ecommerce: null });

        this.pushData(layer);
    }
    add_to_wishlist(){
        var itemData = {
            item_id: this.eventData.id,
            item_name: this.eventData.name,
            affiliation: 'eBooksBridge',
            item_brand: this.eventData.brand,
            price: this.eventData.price,
            quantity: this.eventData.quantity
        };
        if(this.eventData.categories){
            if(this.eventData.categories.length > 0){
                itemData['item_category'] = this.eventData.categories[0];
            }
            this.eventData.categories.forEach(function (category, key){
                if(key>0){
                    itemData['item_category' + (key + 1)] = category;
                }
            })
        }

        let layer = {
            event: this.event,
            ecommerce: {
                currency: "USD",
                value: itemData['price'],
                items: [
                    itemData
                ]
            }
        }
        this.pushData({ ecommerce: null });

        this.pushData(layer);
    }

    eecDetail() {
        let layer = {
            event: this.event,
            ecommerce:{
                detail: {
                    actionField: {
                        list: this.action
                    },
                    products: [JSON.parse(this.eventData)]
                },
            }

        }

        this.pushData(layer);
    }

    eecViewItem() {
        this.pushData({ ecommerce: null });

        let layer = {
            event: this.event,
            ecommerce: JSON.parse(this.eventData)
        }

        this.pushData(layer);
    }

    pushData(data) {
        this.dataLayer.push(data);
    }
}