import $ from "jquery";

$(document).ready(function () {

    $('.carousel').carousel({
        interval: 4500,
        touch: true
    });
    $(".splide__slide img").click(function () {
        $(this).zoomTo({targetsize: 0.75, duration: 600});
        evt.stopPropagation();
    });

    $(window).scroll(function () {
        var sticky = $('header'),
            scroll = $(window).scrollTop();

        if (scroll >= 600)
            sticky.addClass('fixed');
        else
            sticky.removeClass('fixed');
        $(".christmas-header").removeClass('fixed')
    });

//    filter words
    $(".filter-block ul li a").click(function () {
        $(this).toggleClass("active")
    })

    $('.toggle-filter').click(function (e) {
        $('.sidebar .widgets').slideToggle(function () {
            $('.sidebar').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.widget h4').click(function (e) {
        $(this).siblings('.widget-box').slideToggle(function () {
            $(this).closest('.widget').toggleClass('widget-active');
        });
        e.preventDefault();
    });

    $('.aside-dropdown .dd-menu > a').click(function (e) {
        $(this).siblings('ul').slideToggle(function () {
            $(this).parent().toggleClass('dd-menu-active');
        });
        e.preventDefault();
    });

    $('header .search-btn').click(function () {
        $('header .search').toggleClass('show');
    });

    $('.nav-btn').click(function () {
        $(this).toggleClass('open');
        $('nav').toggleClass('open');
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.dropdown-btn').click(function () {
            $(this).siblings('ul, .mega-menu, div').slideToggle();
            $(this).find('.fa').toggleClass('fa-angle-down fa-angle-up');
        });

    } else {
        $('.menu > li').hover(function () {
            $(this).find('.mega-menu, .sub-menu').first().stop().slideDown(200);
        }, function () {
            $(this).find('.mega-menu, .sub-menu').stop().slideUp(200);
        });
    }

    let productsCarousel = $('.owl-products').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 2,
                margin: 10,
                stagePadding: 0
            },
            400: {
                items: 2,
                margin: 10,
                stagePadding: 0
            },
            490: {
                items: 2,
                margin: 10,
                stagePadding: 10
            },
            600: {
                items: 2,
                stagePadding: 50
            },
            940: {
                items: 4,
            },
            1200: {
                items: 5,
            },
            1600: {
                items: 5,
                margin: 30,
            }
        }
    });

    $('.owl-products-no-clone').owlCarousel({
        rewind: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 25
            },
            360: {
                items: 1,
                nav: false,
                stagePadding: 50
            },
            425: {
                items: 1,
                nav: false,
                stagePadding: 80
            },
            500: {
                items: 2,
                nav: false,
                stagePadding: 50
            },
            700: {
                items: 3,
                nav: false,
                stagePadding: 50
            },
            940: {
                items: 4,
                nav: true
            },
            1200: {
                items: 4,
                nav: true,
                stagePadding: 80
            },
            1300: {
                items: 6,
                nav: true,
                margin: 30,
            }
        }
    });


    $('.slider-module .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    })


    $('.product-slide-module .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                stagePadding: 0,
                nav: false,
                items: 2
            },
            426: {
                stagePadding: 0,
                nav: false,
                items: 2
            },
            660: {
                stagePadding: 40,
                nav: false,
                items: 3
            },
            767: {
                items: 3
            },
            796: {
                items: 4
            },
            1150: {
                items: 5
            },
            1316: {
                items: 6
            }
        }
    })


    $('.slide-offer .owl-carousel').owlCarousel({

        loop: false,
        margin: 10,
        nav: false,
        responsive: {
            0: {
                stagePadding: 50,
                items: 1
            },
            450: {
                stagePadding: 20,
                items: 2
            },
            580: {
                stagePadding: 30,
                items: 3
            }
        }
    })

    $('.categories-slider .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 2
            },
            450: {
                items: 3
            },
            600: {
                items: 4
            },
            1000: {
                items: 5,
                margin: 20
            },
            1300: {
                items: 6,
                margin: 25
            }
        }
    })

    $('.authors.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            374: {
                items: 1,
                stagePadding: 20
            },
            455: {
                items: 1,
                stagePadding: 45
            },
            600: {
                items: 2
            },
            1000: {
                items: 3,
                margin: 20
            }
        }
    })

    $('.review-slider .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            425: {
                items: 1,
                stagePadding: 35
            },
            575: {
                items: 2,
            },
            760: {
                items: 2,
                margin: 20,
                stagePadding: 40
            },
            1000: {
                items: 3,
                margin: 20
            },
            1270: {
                items: 4,
                margin: 25,
                nav: true
            }
        }
    })

    $('.partners-carousel.owl-carousel').owlCarousel({
        loop: true,
        dots: false,
        margin: 10,

        nav: true,

        responsive: {
            0: {
                items: 2,
                autoWidth: true
            },
            420: {
                items: 3,
                autoWidth: true
            },
            575: {
                items: 4
            },
            768: {
                items: 7,
                touchDrag: false,
                mouseDrag: false,
            },
            1024: {
                items: 7,
                touchDrag: false,
                mouseDrag: false,
                margin: 10
            },
            1220: {
                items: 7,
                touchDrag: false,
                mouseDrag: false,
                autoWidth: true,
                margin: 25
            }
        }
    })

    $("header .fixed-search button").click(function () {
        $("header .search").addClass("open")
    })

    $("header .search input").click(function () {
        $("header .search").addClass("open")
    })

    $(function () {

        $(document).on("click", function (e) {
            if ($(e.target).is("header .search, header .search button, header .search button img, header .search input, header .search-btn, header .fixed-search button, header .fixed-search button img") === false) {
                $("header .search").removeClass("open")
            }
        });
    });

    $(function () {

        var maxL = 340;

        $('.content').each(function () {

            var text = $(this).text();
            if (text.length >= maxL + 20) {

                var begin = text.substr(0, maxL),
                    end = text.substr(maxL);

                $(this).html(begin)

                    .append($('<div class="dots"/> ').html('...'))
                    .append($('<button class="readmore btn btn-link sec p-0"/> ').html('read more...'))
                    .append($('<div class="hidden" />').html(end));
            }
        });

        $(document).on('click', '.readmore', function () {
            // $(this).next('.readmore').fadeOut("400");
            $(this).next('.hidden').slideToggle(0);
            $(this).parent().toggleClass("open")
        })
    })

    const throttleFunction = (func, delay) => {

        // Previously called time of the function
        let prev = 0;
        return (...args) => {
            // Current called time of the function
            let now = new Date().getTime();

            // Logging the difference between previously
            // called and current called timings

            // If difference is greater than delay call
            // the function again.
            if (now - prev > delay) {
                prev = now;

                // "..." is the spread operator here
                // returning the function with the
                // array of arguments
                return func(...args);
            }
        }
    }


    function triggerOnScroll(owl, e) {
        let deltaX = e.originalEvent.deltaX;
        let deltaY = e.originalEvent.deltaY;

        if (deltaY <= -1 || deltaY >= 1) {
            return;
        }
        if (deltaX > 0) {
            productsCarousel.trigger('next.owl');
        } else {
            productsCarousel.trigger('prev.owl');
        }
        e.preventDefault();
    }

    productsCarousel.on('wheel', '.owl-stage', throttleFunction((e) => {
        triggerOnScroll(productsCarousel, e);
    }, 1000));


});


// scrollTop Y function
function scrollTo(position, speed) {

    speed = (typeof speed === "undefined") ? 500 : speed;

    $('html, body').animate({
        scrollTop: position
    }, speed);
}

// $(document).ready(function() {
//     $("#lang").val('ca');
//     $("#lang").select2({
//
//     templateResult: function (idioma) {
//         // var $span = $("<span><img src='https://www.free-country-flags.com/countries/"+idioma.id+"/1/tiny/" + idioma.id + ".png'/> " + idioma.text + "</span>");
//         var $span = $("<span><img src='https://bitpay.com/img/flags-round/" + idioma.id + ".svg'/> " + idioma.text + "</span>");
//         return $span;
//     },
//     templateSelection: function (idioma) {
//         var $span = $("<span><img src='https://bitpay.com/img/flags-round/" + idioma.id + ".svg'/> " + idioma.text + "</span>");
//         // var $span = $("<span><img src='https://bitpay.com/img/flags-round/ca" + ".svg'/> " + idioma.text + "</span>");
//         return $span;
//     }
// });
// });

