import {Controller} from "stimulus";

export default class extends Controller {

    change() {
        let label = this.element.parentNode.querySelector('.custom-file-label');

        if (this.element.files.length !== 0) {
            label.innerHTML = this.element.files[0].name.length > 20 ? this.element.files[0].name.substring(0, 20) + '...' : this.element.files[0].name;
            $(label).append(this.successElement);
        } else {
            label.innerHTML = "-- No file chosen --";
        }
    }

    initialize() {

        this.createSuccess();

        //TODO: Translate this.
        let label = this.element.parentNode.querySelector('.custom-file-label');
        label.innerHTML = "-- No file chosen --";
    }

    createSuccess() {
        this.successElement = $('<span>');
        $(this.successElement).addClass('success').append('<i class="fa fa-check"></i>');
    }

}