import Vue from 'vue/dist/vue.esm.js';
import 'ion-rangeslider/js/ion.rangeSlider';

import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
import 'select2';
import $ from "jquery";

Vue.component('range-filter', {
    template: '#range-filter',
    delimiters: ['[[', ']]'],
    props: {
        minRange: {
            required: true,
            type: Number,
        },
        maxRange: {
            required: true,
            type: Number,
        },

        imin: {
            required: true,
            type: Number,
        },
        imax: {
            required: true,
            type: Number,
        },
        priceType: {
            required: false,
            type: String,
            default: 'printPriceUSD'
        }

    },

    data() {
        return {
            property: 'price_range',
            propType: this.priceType,
            min: this.imin,
            max: this.imax,
        }
    },


    mounted() {
        if (this.min === 0) {
            this.min = this.minRange;
        }
        if (this.max === 0) {
            this.max = this.maxRange;
        }
        this.initSlider();
        this.initPriceSelect2();
    },

    methods: {
        initPriceSelect2(){
            let _self = this;
            let currencySelect = $(this.$refs.currencySelect);
            currencySelect.select2({

                templateResult: function (currency) {
                    if(currency.id){
                        var $span = $("<span><img src='/build/images/masterpointpress/icons/flag-" + currency.text.toLowerCase() + ".svg'/> " + currency.text + "</span>");

                        return $span;
                    }
                },
                templateSelection: function (currency) {
                     var $span = $("<span><img src='/build/images/masterpointpress/icons/flag-" + currency.text.toLowerCase() + ".svg'/> " + currency.text + "</span>");

                    return $span;
                }
            });

            $(currencySelect).on("select2:select", function (e) {
                _self.propType = e.params.data.id;

                _self.changeType();
            });
        },

        initSlider() {

            let slider = $(this.$refs.slider).data("ionRangeSlider");

            if (slider) {
                slider.destroy();
            }

            let _self = this;
            $(this.$refs.slider).ionRangeSlider({
                skin: "round",
                type: "double",
                min: _self.minRange,
                max: _self.maxRange,
                hide_min_max: true,
                from: _self.min,
                to: _self.max,
                onChange: function (data) {
                    _self.rangeChanged(data);
                },
                onUpdate: function (data) {
                    _self.changeFilter(data);

                },
                onFinish: function (data) {
                    _self.changeFilter(data);
                }
            });
        },

        rangeChanged(data) {
            this.min = data.from;
            this.max = data.to;
        },

        changeType(){
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set('price_type', this.propType);
            searchParams.delete('page');
            history.replaceState(null, null, ' ');
            window.location.search = searchParams.toString();
        },
        changeFilter(data) {

            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set(this.property, data.from + ';' + data.to);
            searchParams.set('price_type', this.propType);

            searchParams.delete('page');
            history.replaceState(null, null, ' ');
            window.location.search = searchParams.toString();

        },
        changeMin() {
            let slider = $(this.$refs.slider).data("ionRangeSlider");
            slider.update({
                from: this.min,
            })
        },
        changeMax() {
            let slider = $(this.$refs.slider).data("ionRangeSlider");
            slider.update({
                to: this.max,
            })
        }

    }
    ,
    computed: {}
});