import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["source"];

    copy(event) {
        event.preventDefault();

        if (!navigator.clipboard) {
            console.error('Clipboard API not available');
            return;
        }

        let content = this.text;
        let input = event.target;

        navigator.clipboard.writeText(content).then(function () {
            input.innerHTML = '';
            input.innerHTML = '<i class="fa fa-check"></i> Copied';
        })
            .catch(function (err) {
                console.error('Could not copy text: ', err);
            });


    }

    get text() {
        return this.sourceTarget.value;
    }
}