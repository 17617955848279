import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ['image']

    connect() {
        this.url = this.element.dataset.url;
        this.id = this.element.dataset.id;
        this.action = this.element.dataset.context;
    }

    addToCart(event) {
        event.preventDefault();
        this.buildAjax(this.url, this.id);
    }

    buildAjax(url, id) {
        let _self = this
        $.ajax({
            data: {id: id},
            url: url,
            method: 'POST',
            success: function (result) {
                _self.successAjax(result)
            },
        });
    }

    successAjax(result) {
        let modal = $('#checkout-modal');
        modal.modal('show');

        let successMessage = $('#success');
        let failMessage = $('#fail');

        if (result.success === false) {
            successMessage.hide();
            failMessage.text(result.message);
            failMessage.addClass('cart-delivery-hint');
            failMessage.show();

            return;

        } else {
            successMessage.show();
            failMessage.hide();
        }

        EventManager.fire('item-added', result.cart);

        let event = {
            event: 'add_to_cart',
            eventData: result.variant,
            unique_event_id: result.unique_event_id,
            action: this.action,
        }
        EventManager.fire('gtm-event', event);
    }
}
